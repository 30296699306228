import { Container, Spinner } from '@jds/core';
import React from 'react';

import './FullPageSpinner.scss';

const FullPageSpinner = (): JSX.Element => {
  return (
    <Container className={'spinner-container'}>
      <Spinner className={'spinner'} data-testid={'spinner'} />
    </Container>
  );
};

export default FullPageSpinner;
