import React from 'react';
import { Router } from 'react-router-dom';
import history from './history';

interface RouterProps {
  children: React.ReactNode;
}

const CustomRouter = ({ children }: RouterProps) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

export default CustomRouter;
