import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import './i18n';

// We have 2 options for preloading or let's better say forcing fonts preload. The webpack preload feature seems not to
// work :\ Webfontloader on the other hand looks like it does the trick but it is not TS friendly :\

// @ts-ignore
import WebFont from 'webfontloader';

WebFont.load({
  custom: {
    families: ['JioType']
  }
});

// import(/* webpackPreload: true */ './fonts/JioType-Medium.ttf');
// import(/* webpackPreload: true */ './fonts/JioType-Black.ttf');
// import(/* webpackPreload: true */ './fonts/JioType-Bold.ttf');

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
