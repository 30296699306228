import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FullPageSpinner from '../components/Loader/FullPageSpinner';

const Login = React.lazy(() => import('../pages/Login'));
const PasswordRecovery = React.lazy(() => import('../pages/PasswordRecovery'));
const RegisterUser = React.lazy(() => import('../pages/RegisterUser'));
const Error500 = React.lazy(() => import('../pages/Error500'));

function AppRoutes() {
  return (
    <Routes>
      <Route
        path='login/partner-signup'
        element={
          <React.Suspense fallback={<FullPageSpinner />}>
            <RegisterUser />
          </React.Suspense>
        }
      />
      <Route
        path='login/reset-password'
        element={
          <React.Suspense fallback={<FullPageSpinner />}>
            <PasswordRecovery />
          </React.Suspense>
        }
      />
      <Route
        path='sorry'
        element={
          <React.Suspense fallback={<FullPageSpinner />}>
            <Error500 />
          </React.Suspense>
        }
      />
      <Route
        path='*'
        element={
          <React.Suspense fallback={<FullPageSpinner />}>
            <Login />
          </React.Suspense>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
