import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { BASE_URL } from './config';
import { NAMESPACE } from './i18n/i18n.enum';

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      backend: {
        loadPath: `${BASE_URL}/microcopy?group={{ns}}&language={{lng}}&brandId=${process.env.REACT_APP_BRAND_ID}`
      },
      // debug: process.env.NODE_ENV === 'development',
      defaultNS: NAMESPACE.common,
      detection: {
        order: ['navigator'],
        lookupQuerystring: 'lng'
      },
      fallbackLng: false,
      interpolation: {
        escapeValue: false
      },
      ns: [NAMESPACE.common], // because without i18next will use default called "translation"...
      react: {
        useSuspense: true
      }
    },
    (err, t) => {
      if (err) return console.error(err);
    }
  );

export default i18next;
