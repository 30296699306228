import './App.scss';
import CustomRouter from './routes/customRouter';
import AppRoutes from './routes/routes';

function App() {
  return (
    <div>
      <CustomRouter>
        <AppRoutes />
      </CustomRouter>
    </div>
  );
}

export default App;
